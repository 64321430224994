(function($){
  $.universalSearch = function(el, options){
    // To avoid scope issues, use 'base' instead of 'this'
    // to reference this class from internal events and functions.
    var base = this;

    // Access to jQuery and DOM versions of element
    base.$el = $(el);
    base.el = el;

    // Add a reverse reference to the DOM object
    base.$el.data("universalSearch", base);

    base.init = function() {
      //Maybe turn off for IE11 here
      base.options = $.extend({},$.universalSearch.defaultOptions, options);

      base.setupUI(base.$el.find('.link.universal-search'));

      base.installDecorators();

    };

    base.installDecorators = function() {
      _.each(base.decorators, function(val,key) {
        base.$container.find(key).live.apply($(key), val);
      });
    };

    base.decorators = {
      '.search-container': [
        'click', function(e) {
          $.fluxx.util.itEndsHere(e);
          base.close();
        }
      ],
      '.search-content': [
        'click', function(e) {
          $.fluxx.util.itEndsHere(e);
        }
      ],
      'a.new-detail': [
        'click', function(e) {
          $(this).bind('card-added', function() {
            base.close();
          });
        }
      ]
    };

    base.setupUI = function($where) {
      base.url = $.fn.cleanupURL($where.click(base.open).next().find('form').attr('action'));
      console.log(base.url);
      $where.next().remove();
      base.$container = $('<div id="ios-search-container"><div id="ios-search-content"><input class="search-input" placeholder="Universal Search..." type="text"/><div class="search-results"/><div class="modal-close"><img src="/images/fluxx_engine/theme/ios/icons/cancel_search_2x.png"></div></div></div>"');
      $('#header').append(base.$container);
      base.$input = base.$container.find('.search-input').keypress(base.checkForEnter);
      base.$results = base.$container.find('.search-results');
      base.$content = base.$container.find('.search-content');
      base.$container.find('.modal-close').click(base.close);

      $(document).keyup(function(e) {
        if (e.keyCode == 27) base.close()
      });
    };

    base.close = function() {
      base.$container.attr('class', '');
      base.$results.html('');
      base.$input.val('');
    };

    base.open = function(e) {
      $.fluxx.util.itEndsHere(e);
      base.$container.addClass('open');
      base.$input.focus();
    };

    base.checkForEnter = function(e) {
      if (e.keyCode == 13) {
        base.ajaxSearch();
      }
    };

    base.ajaxSearch = function() {
      if (base.loading) return;
      base.loading = true;
      if (base.$input.val()) {
        $.ajax({
          beforeSend: function(jqXHR, settings) {
            base.$container.addClass('loading');
            base.$input.prop('disabled', true);
          },
          complete: function(jqXHR, settings) {
            base.$container.removeClass('loading');
            base.$input.prop('disabled', false);
            base.loading = false;
          },
          type: 'post',
          url: base.url,
          data: [{name: 'q', value: base.$input.val()}],
          success: function(data, status, xhr) {
            base.$container.removeClass('no-results');
            base.$container.addClass('have-results');
            var $data = $('<div>' + data + '</div>');
            if ($data.find('.card')[0]) {
              base.$results.html('<div class="listing"/>');
              base.$listing = base.$results.find('.listing');
              base.$listing.html(data);
              base.$listing.areaDetailTransform();
            } else {
              base.$container.addClass('no-results');
              base.$results.html(data);
            }
          }
        });
      }
    };

    // Run initializer
    base.init();
  };

  $.universalSearch.defaultOptions = {
  };

  $.fn.universalSearch = function(options){
    return this.each(function(){
      (new $.universalSearch(this, options));
    });
  };

  $.fluxx.addPlugin('universalSearch');

})(jQuery);